/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../scss/app.scss');
require('../css/horizontal_menu.css');
require('../css/timeline.css');
require('../css/inputs.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
global.$ = global.jQuery = $;

const jqueryUi = require('jquery-ui');
global.jqueryUi = jqueryUi;

const select2 = require('select2');
global.select2 = select2;
require('select2/dist/css/select2.min.css');

const datepicker = require('bootstrap-datepicker');
global.datepicker = datepicker;
require('../../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');

const timepicker = require('timepicker');
global.timepicker = timepicker;

require('bootstrap');

const dataTables = require('datatables');
global.dataTables = dataTables;
require('datatables/media/css/jquery.dataTables.min.css');

const swal = require('sweetalert2');
global.swal = swal;

const autosize = require('autosize');
global.autosize = autosize;

const fancybox = require('@fancyapps/fancybox');
global.fancybox = fancybox;
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

// Load Highcharts
var Highcharts = require('highcharts');
global.Highcharts = Highcharts;
require('../../node_modules/highcharts/modules/exporting')(Highcharts);

require('timepicker/jquery.timepicker.css');